import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthSuperadminGuard, AuthVendorGuard } from './_guards';
// canActivate: [AuthSuperadminGuard],
const routes: Routes = [
 
  { path: 'login', loadChildren: () => import('./login/login.module').then(m => m.LoginModule) },
  { path: '', loadChildren: () => import('./image/image.module').then(m => m.ImageModule),canActivate: [AuthSuperadminGuard]},
  
  { path: 'image', loadChildren: () => import('./image/image.module').then(m => m.ImageModule),canActivate: [AuthSuperadminGuard]},
  { path: 'event', loadChildren: () => import('./event/event.module').then(m => m.EventModule),canActivate: [AuthSuperadminGuard]},
  { path: 'registration', loadChildren: () => import('./registration/registration.module').then(m => m.RegistrationModule),canActivate: [AuthSuperadminGuard]},
  { path: 'institution-user', loadChildren: () => import('./institute_user/institute_user.module').then(m => m.InstituteUserModule),canActivate: [AuthSuperadminGuard]},
  { path: 'roi-user', loadChildren: () => import('./roi_user/roi_user.module').then(m => m.RoiUserModule),canActivate: [AuthSuperadminGuard]},
  { path: 'report-user', loadChildren: () => import('./report_user/report_user.module').then(m => m.ReportUserModule),canActivate: [AuthSuperadminGuard]},
  { path: 'venue-user', loadChildren: () => import('./venue_user/venue_user.module').then(m => m.VenueUserModule),canActivate: [AuthSuperadminGuard]},
  { path: 'food-user', loadChildren: () => import('./food_user/food_user.module').then(m => m.FoodUserModule),canActivate: [AuthSuperadminGuard]},
  { path: 'pr-user', loadChildren: () => import('./pr-user/pr-user.module').then(m => m.PrUserModule),canActivate: [AuthSuperadminGuard]},

  { path: 'on-postal', loadChildren: () => import('./on-postal/on-postal.module').then(m => m.OnPostalModule),canActivate: [AuthSuperadminGuard]},
  { path: 'transaction', loadChildren: () => import('./transaction/transaction.module').then(m => m.TransactionModule),canActivate: [AuthSuperadminGuard]},
  { path: 'refund', loadChildren: () => import('./refund/refund.module').then(m => m.RefundModule),canActivate: [AuthSuperadminGuard]},
  { path: 'feedback', loadChildren: () => import('./feedback/feedback.module').then(m => m.feedbackModule),canActivate: [AuthSuperadminGuard]},
  { path: 'seating-arrangement', loadChildren: () => import('./seating_arrangement/seating_arrangement.module').then(m => m.SeatingArrangementModule),canActivate: [AuthSuperadminGuard]},
  { path: 'settings', loadChildren: () => import('./setting/setting.module').then(m => m.SettingModule),canActivate: [AuthSuperadminGuard]},
  { path: 'employee_registration', loadChildren: () => import('./employee-registration/employee-registration.module').then(m => m.EmployeeRegistrationModule),canActivate: [AuthSuperadminGuard]},
   { path: 'postal-images', loadChildren: () => import('./postal-images/postal-images.module').then(m => m.PostalImagesModule),canActivate: [AuthSuperadminGuard]},
   { path: 'event-slot-configuaration', loadChildren: () => import('./slot-config/slot-config.module').then(m => m.SlotConfigModule),canActivate: [AuthSuperadminGuard]},
   { path: 'hold-reason', loadChildren: () => import('./hold-reason/hold-reason.module').then(m => m.HoldReasonModule),canActivate: [AuthSuperadminGuard]},
   { path: 'helpline-number', loadChildren: () => import('./helpline-numbe/helpline-numbe.module').then(m => m.HelplineNumbeModule),canActivate: [AuthSuperadminGuard]},
   { path: 'breaking-news', loadChildren: () => import('./breaking-news/breaking-news.module').then(m => m.BreakingNewsModule),canActivate: [AuthSuperadminGuard]},
   { path: 'create-seat', loadChildren: () => import('./create-seat/create-seat.module').then(m => m.CreateSeatModule),canActivate: [AuthSuperadminGuard]},
   { path: 'bulk-email', loadChildren: () => import('./bulk-email/bulk-email.module').then(m => m.BulkEmailModule),canActivate: [AuthSuperadminGuard]},

  {
    
    path: '**',
    redirectTo: '',
    pathMatch: 'full'
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
