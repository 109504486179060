export const environment = {
  appName: "MAHE_CONVOCATION_ADMIN",
  production: true,
  // apiUrl: 'http://devapi-convocation.manipal.edu/',
  apiUrl:'https://devapi-convocation.manipal.edu/',
  // apiUrl: 'https://api.manipalsuperadmin.com/',
  oneSignalAppId: 'c382baff-142f-4e16-b7c4-f6133d400ea4', //dev
   media_domain: 'https://dzklmbj651gn4.cloudfront.net/development', //dev
    // media_domain: 'https://dzklmbj651gn4.cloudfront.net/production',
  apiPrefix: 'api/v1',

};
